export class AuthStorageData {
  user_id?: number;
  email?: string; // logged user email
  token?: string;
  authenticated?: boolean; // redundant flag
}

export async function saveAuthData(authData: AuthStorageData) {
  localStorage.setItem("auth", JSON.stringify(authData));
}

export function loadAuthData() {
  const data = localStorage.getItem("auth");
  return data? JSON.parse(data) as AuthStorageData: new AuthStorageData();
}

export function clearAll() {
  localStorage.clear();
}

