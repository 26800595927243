// file imported from backend app
console.log("--- CONSTS FILE LOADING ---");
export const DATE_FORMAT = "DD.MM.YYYY";
export const TIME_FORMAT = "HH:mm";
export const FULL_TIME_FORMAT = "HH:mm:ss";
export const DATE_TIME_FORMAT = "DD.MM.YYYY HH:mm";
export const DB_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm";
export const DB_DATE_FORMAT = "YYYY-MM-DD";

export const NEW_ID_START = 100000;
export const TEST_ID_FIRST = 200000;
export const TEST_ID_SECOND = 200001;
export const TEST_ID_THIRD = 200002;

export const TEMP_SERVER_ID = 500000;
export const MAX_LEVELS = 4;
export const MAX_SERVICE_AREAS = 30;

export const SERVER_COMMUNICATION_ERROR = "A server communication error has occurred. Please try again later.";

export default interface EnumComplex {
  id: number;
  name: string;
  symbol?: string;
}

export const Months = [
  "Styczeń",
  "Luty",
  "Marzec",
  "Kwiecień",
  "Maj",
  "Czerwiec",
  "Lipiec",
  "Sierpień",
  "Wrzesień",
  "Październik",
  "Listapad",
  "Grudzień",
];

export enum ModalEvents {
  CANCEL_EVENT = "modal_cancel",
  OK_EVENT = "modal_ok",
  TIME_CHANGED_EVENT = "time_changed",
  CHANGE_DATE_EVENT = "change_date",
  DELETE_EVENT = "modal_delete",
}

export enum Events {
  OK_EVENT = "ok",
  CANCEL_EVENT = "cancel",
  UPLOAD_EVENT = "upload",
  REMOVE_EVENT = "remove",
}

export enum PageMode {
  PREVIEW = "preview",
  NEW = "new",
  EDIT = "edit",
}

export enum CalendarViewType {
  DAY = "day",
  MONTH = "month",
  PERSON_DAY = "person_day",
  VISIT = "visit",
}

export enum ErrorCodes {
  USER_EMAIL_EXISTS = 1,
  USER_EXISTS = 2,
  COMPANY_NIP_EXISTS = 3,
  OBJECT_NOT_FOUND = 4,
  WRONG_PASSWORD = 5,
  CODE_EXISTS = 6,
  TECHNICIAN_LOGGING_IN = 7,
  TASK_LIST_EXISTS = 8,
  CUSTOMER_USER_LOGGING_IN = 9,
  COLOR_EXISTS = 10,
  COLOR_IS_USED = 11,
}

export enum TGACompany {
  ID = 1,
  NAME = "TGA GmbH",
  TGA_ADMIN_ID = 1,
}

export enum UserType {
  NOT_DEFINED = 0,
  CUSTOMER_USER = 1,
  TECHNICIAN = 2,
  TGA_MANAGER = 3,
  TGA_ADMIN = 4,
  CUSTOMER_ADMIN = 5,
  FACILITY_MANAGER = 6,
}

export enum AccountType {
  USER = 0,
  COMPANY = 1,
}

export enum TemplateType {
  TRADE = 0,
  WORK_TYPE = 1,
  TASK_LIST = 2,
  TASK = 3,
  SKILL = 4,
  EVENT = 5,
}

export enum TemplateTypeStr {
  TRADE = "Trade",
  WORK_TYPE = "Service",
  TASK_LIST = "Task List",
  TASK = "Task",
  SKILL = "Skill",
}

export enum TaskTypeStr {
  MAINTENANCE = "Maintenance",
  REPAIR = "Repair",
  INSTALLATION = "Installation",
  CUSTOM = "Custom",
}

export enum UserTypeStr {
  NOT_DEFINED = "NotDefined",
  CUSTOMER_USER = "Customer User",
  TECHNICIAN = "Technician",
  TGA_MANAGER = "TGA Manager",
  TGA_ADMIN = "TGA Admin",
  CUSTOMER_ADMIN = "Customer Admin",
  FACILITY_MANAGER = "Facility Manager",
}

export enum NavNames {
  DASHBOARD = "dashboard",
  ACCOUNTS = "accounts",
  TECHNICIANS = "technicians",
  TECHNICIAN = "technician",
  TECHNICIAN_CALENDAR = "technicianCalendar",
  TEMPLATES = "templates",
  NOTIFICATIONS = "notifications",
  ACCOUNT = "account",
  TGA_COMPANY = "tgaCompany",
  TGA_ADMIN = "tgaAdmin",
  TGA_MANAGERS = "tgaManagers",
  CUSTOMER_COMPANIES = "companies",
  CUSTOMER_ADMINS = "customerAdmins",
  CUSTOMER_USERS = "customerUsers",
  FACILITY_MANAGERS = "facilityManagers",
  MAIN_VIEW = "mainView",
  FACILITIES = "objects",
  NEW_FACILITY = "newObject",
  FACILITY = "object",
  NEW_CONTRACT = "newContract",
  CONTRACT = "contract",
  ORDERS = "orders",
  SERVICE_AREA_CHOOSER = "service_area_chooser",
  CALENDAR_CHOOSER = "calendar_chooser",
}

export enum NavNamesCustomer {
  DASHBOARD = "dashboard",
  ACCOUNTS = "accounts",
  OBJECTS = "objects",
  SERVICES = "services",
  NOTIFICATIONS = "notifications",
}

export enum RouterNames {
  LOGIN = "login",
  PASSWORD_RESET_REQUEST = "passwordResetRequest",
  PASSWORD_RESET = "passwordReset",
  ORDERS = "orders",
  ORDER_DETAILS = "orderDetails",
  SELECT_TRADE_VIEW = "selectTradeView",
  SELECT_SERVICE_VIEW = "selectServiceView",
  SELECT_TASK_LIST_VIEW = "selectTaskListView",
  TASK_LISTS_VIEW = "taskListsView",
  REPORT_PROBLEM = "reportProblem",
  REPORTED_PROBLEMS = "reportedProblems",
  ACTIVE_ORDER = "activeOrder",
  ORDER_FINISHED = "orderFinished",
  CREATE_PROTOCOL = "createProtocol",
  PROTOCOL_CREATED = "protocolCreated",
  SERVICE_FINISHED_CONFIRMATION_MODAL = "serviceFinishedConfirmationModal",
  USER_PROFILE = "userProfile",
  CHANGE_PASSWORD = "changePassword",
}

export enum BreadcrumbTitles {
  CREATE_TECHNICIAN = "Create technician",
  NEW_TGA_MANAGER = "New TGA Manager",
  TGA_COMPANY = "TGA GmbH",
  NEW_USER = "New user account",
  CREATE_USER_PREFIX = "Create ",
  ADD_EMPLOYEE = "Add employee",
  CREATE_ACCOUNT = "Create account",
  CREATE_COMPANY = "Create Company",
  NEW_FACILITY = "Create object",
  NEW_CONTRACT = "Create contract",
  ORDERS = "Orders",
}

export enum DictType {
  CLIENTS,
  CONSTR_PLACES,
  LOCALIZATIONS,
  DESTINY,
  DESTINY_GROUP,
  MATERIAL,
  CONCRETE_CLASS_GROUPS,
  CONCRETE_CLASS_SUBGROUP,
  CONCRETE_CLASS,
}

export enum SortDir {
  ASC,
  DESC,
}

export const INITIAL_SORT_FIELD = "id";
export const LABORANT_ROUTE_PREFIX = "/laborant";

export enum ExamType {
  CONCRETE,
  GEO,
  CERT,
}

export enum UserRightSymbols {
  VISIT_START = "VISIT_START",
}

export enum TakeExamPlaces {
  BATCHING_PLANT = "W - węzeł betoniarski", // Węzeł betoniarski
  CONSTRUCTION_SITE = "B - budowa", // Budowa
}

export enum ConsistencyNorms {
  S = "12350-2",
  F = "12350-5",
  SF = "12350-8",
}

export enum MakeSampleNorms {
  C = "12350-2",
  ALL = "PN-88",
}

export enum GroupClassSymbols {
  COMPRESSIVE_STREGTH = "C",
  COMPRESSIVE_STREGTH_LIGHT = "LC",
  CONSISTENCY = "S",
  CONSISTENCY_CONE = "SF",
  CONSISTENCY_TABLE = "F(rozpływ)",
  CHLORIDE = "Cl",
  EXPOSURE = "X",
  VISCOSITY = "VF",
  FROZEN = "F",
  WATER = "W",
}

export enum DestinyGroupCodes {
  COMPRESSIVE_STREGTH = "C",
  FROZEN = "F",
  WATER = "W",
}

export enum SlumpTestType {
  OK = "WŁAŚCIWY",
  NOK = "ŚCIĘTY",
}

export enum SignatureType {
  VISIT,
  PROTOCOL,
}

export enum ModuleType {
  LAB = 1,
  CLIENT = 2,
  ADMIN = 3, //to pewnie trzeba bedzie zmienic
}

export enum EventType {
  SINGLE = 1,
  DAY_LONG = 2,
}

export enum TechnicianEventTypeNames {
  SICK = "Sick",
  VACATION = "Vacation",
  ON_CALL = "On Call",
  ABSENT = "Absent",
  BANK_HOLIDAY = "Bank Holiday",
}

export enum TechnicianEventTypeInt {
  SICK = 1,
  VACATION = 2,
  ON_CALL = 3,
  ABSENT = 4,
  BANK_HOLIDAY = 5,
}

export const TechnicianEventTypes = [
  {
    id: TechnicianEventTypeInt.SICK, // to jest type event-u typu integer uzywany w bazie jako event_type
    name: TechnicianEventTypeNames.SICK,
    type: EventType.SINGLE,
    color: "rgb(129, 60, 10)",
  },
  {
    id: TechnicianEventTypeInt.VACATION,
    name: TechnicianEventTypeNames.VACATION,
    type: EventType.SINGLE,
    color: "rgb(234, 187, 66)",
  },
  {
    id: TechnicianEventTypeInt.ABSENT,
    name: TechnicianEventTypeNames.ABSENT,
    type: EventType.SINGLE,
    color: "rgb(144, 102, 235)",
  },
  {
    id: TechnicianEventTypeInt.ON_CALL,
    name: TechnicianEventTypeNames.ON_CALL,
    type: EventType.SINGLE,
    color: "rgb(216, 57, 76)",
  },
  {
    id: TechnicianEventTypeInt.BANK_HOLIDAY,
    name: TechnicianEventTypeNames.BANK_HOLIDAY,
    type: EventType.DAY_LONG,
    color: "rgb(238, 144, 229)",
  },
];


export const dayShortNames = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
export const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const dayIndexes = [
  {
    index: 0,
    name: "Monday",
  },
  {
    index: 1,
    name: "Tuesdays",
  },
  {
    index: 2,
    name: "Wednesday",
  },
  {
    index: 3,
    name: "Thursday",
  },
  {
    index: 4,
    name: "Friday",
  },
  {
    index: 5,
    name: "Saturday",
  },
  {
    index: 6,
    name: "Sunday",
  },
];

export const FRI_INDEX = 4;
export const SAT_INDEX = 5;
export const SUN_INDEX = 6;

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const IND_AVA = {
  DEFAULT_START_TIME: "08:00:00",
  DEFAULT_END_TIME: "16:00:00",
  DEFAULT_FRI_END_TIME: "14:00:00",
  DEFAULT_TOTAL_HOURS: 38,
};

export enum DeactivatableUsers {
  CustomerUser,
  CustomerAdmin,
  FacilityManager,
  TgaManager,
  Technician,
}

export const UPPERCASE_LETTERS = "ABCDEFGHIJKLMNOPRSTUVWZ" as const;

export enum OrderStage {
  PLANNED = 1,
  CONFIRMED = 2,
  DONE = 3,
  RELEASE = 4,
}

export enum OrderStatus {
  ON_THE_ROAD = 1,
  IN_PROGRESS = 2,
  COMPLETE = 3,
  POSTPONED = 4,
}

export const OrderStatusColor = {
  [OrderStatus.ON_THE_ROAD]: "#4690D0",
  [OrderStatus.IN_PROGRESS]: "#EABB42",
  [OrderStatus.COMPLETE]: "#3FBC69",
  [OrderStatus.POSTPONED]: "#D8394C",
};

export const OrderStatusName = {
  [OrderStatus.ON_THE_ROAD]: "On the road",
  [OrderStatus.IN_PROGRESS]: "In progress",
  [OrderStatus.COMPLETE]: "Complete",
  [OrderStatus.POSTPONED]: "Postponed",
};
console.log("--- CONSTS FILE LODING END ---");
