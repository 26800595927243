
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenu,
  IonToolbar,
  IonButton,
  IonMenuToggle,
  IonItem,
  IonList,
} from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { menuController } from "@ionic/core";
import router from '@/router';
import LogoItem from "@/components/LogoItem.vue";
import * as consts from "@/consts";
export default defineComponent({
  name: 'TheSidebar',
  components: {
    LogoItem,
    IonButtons,
    IonContent,
    IonHeader,
    IonMenu,
    IonToolbar,
    IonButton,
    IonMenuToggle,
    IonItem,
    IonList,
  },
  data() {
    return {
      name: "Child 2",
      logoType: "3"
    };
  },
  methods: {
    changeName: function(newName: any) {
      this.name = newName;
    },
    navigateToPage: function (page: string) {
      router.push(page);
      setTimeout(() => menuController.toggle(), 250);
    },
    goToUserProfile(){
      this.$router.push({
        name: consts.RouterNames.USER_PROFILE,
      });
    }
  },
  setup(){
    const router = useRouter();
    return {
      router,
      menuController
    }
  }
});
