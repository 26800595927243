import * as consts from "@/consts";
import AuthModule from "@/store/modules/auth";

export function isUserLoggedIn(): boolean {
  return AuthModule.currentUser.username !== undefined;
}

export function isUserPermitted(userTypes: number[]): boolean {
  return userTypes.includes(<number>AuthModule.currentUser.user_type);
}

export function isLoggedIn() {
  const obj = { TGAManager: false, TGAAdmin: false, Customer: false };
  switch (AuthModule.currentUser.user_type) {
    case consts.UserType.TGA_MANAGER:
      obj.TGAManager = true;
      break;
    case consts.UserType.TGA_ADMIN:
      obj.TGAAdmin = true;
      break;
    default:
      obj.Customer = true;
  }
  return obj;
}
export function getAccountTypeStr(type: number): string {
  switch (type) {
    case consts.UserType.TGA_MANAGER:
      return consts.UserTypeStr.TGA_MANAGER;
    case consts.UserType.TGA_ADMIN:
      return consts.UserTypeStr.TGA_ADMIN;
    case consts.UserType.TECHNICIAN:
      return consts.UserTypeStr.TECHNICIAN;
    case consts.UserType.CUSTOMER_ADMIN:
      return consts.UserTypeStr.CUSTOMER_ADMIN;
    case consts.UserType.CUSTOMER_USER:
      return consts.UserTypeStr.CUSTOMER_USER;
    case consts.UserType.FACILITY_MANAGER:
      return consts.UserTypeStr.FACILITY_MANAGER;
    default:
      return "Company";
  }
}
