import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cdb7db34"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_ripple_effect = _resolveComponent("ion-ripple-effect")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    class: "button-container ion-activatable",
    disabled: _ctx.disabled,
    lines: "none"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["bottom-button swiper-forward", [_ctx.backgroundColor, _ctx.textColor, { unclickable: !_ctx.store.state.handleBottomButtonClick}]])
      }, [
        (_ctx.arrowPrev && _ctx.textColor === 'color-2')
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: require('@/assets/prev-white.svg')
            }, null, 8, _hoisted_1))
          : _createCommentVNode("", true),
        (_ctx.arrowPrev && _ctx.textColor === 'color-3')
          ? (_openBlock(), _createElementBlock("img", {
              key: 1,
              src: require('@/assets/prev-blue.svg')
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true),
        (_ctx.arrowPrev && _ctx.textColor === 'color-4')
          ? (_openBlock(), _createElementBlock("img", {
              key: 2,
              src: require('@/assets/prev-gray.svg')
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true),
        _createElementVNode("span", {
          class: _normalizeClass(_ctx.textColor)
        }, _toDisplayString(_ctx.text), 3),
        (_ctx.arrowNext && _ctx.textColor === 'color-2')
          ? (_openBlock(), _createElementBlock("img", {
              key: 3,
              src: require('@/assets/next-white.svg')
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true),
        (_ctx.arrowNext && _ctx.textColor === 'color-3')
          ? (_openBlock(), _createElementBlock("img", {
              key: 4,
              src: require('@/assets/next-blue.svg')
            }, null, 8, _hoisted_5))
          : _createCommentVNode("", true),
        (_ctx.arrowNext && _ctx.textColor === 'color-4')
          ? (_openBlock(), _createElementBlock("img", {
              key: 5,
              src: require('@/assets/next-gray.svg')
            }, null, 8, _hoisted_6))
          : _createCommentVNode("", true)
      ], 2),
      _createVNode(_component_ion_ripple_effect)
    ]),
    _: 1
  }, 8, ["disabled"]))
}