import { InjectionKey } from 'vue';
import {createStore, useStore as baseUseStore, Store, MutationTree, createLogger,} from 'vuex';
import * as consts from "@/consts";
import { Commit } from 'vuex';
import * as types from "@/types";
// interfaces for our State
const tabsVisible = true;
const tabsMaintenanceRepairVisible = false;
const bottomButtonVisible = false;
const bottomButtonBackgroundColor = 'background-color-3';
const bottomButtonTextColor = 'color-2';
const bottomButtonText = 'Next';
const bottomButtonPrev = false;
const bottomButtonNext = false;
const bottomButtonRouterDirection = 'forward';
const bottomButtonClick: any = '';
const bottomButtonId = '';
const handleBottomButtonClick = true;
const order = null;
const remainingTime = null;
const trade = null;
const service = null;
const taskList = null;
const renderRelatedModal = false;
const editedTaskLists: types.EditedTaskLists[] = [];

interface TimerState {
    timers: Timer[];
}

interface Timer {
    orderId: number;
    isRunning: boolean;
    isFinished: boolean;
    seconds: number;
    formattedTimer: any;
    intervalId: any;
    stopwatchStartTimes: any[];
}

interface StopwatchStart {
    startTime: number;
    endTime: number | null;
}


export type State = {
    // show default tabs
    tabsVisible: boolean,
    // show tabs with maintenance and repair
    tabsMaintenanceRepairVisible: boolean,
    // show bottom button
    bottomButtonVisible: boolean,
    // button background color
    bottomButtonBackgroundColor: string,
    // button text color
    bottomButtonTextColor: string,
    // button text val
    bottomButtonText: string,
    // button show prev arrow
    bottomButtonPrev: boolean,
    // button show next arrow
    bottomButtonNext: boolean,
    // button router animation direction
    bottomButtonRouterDirection: string,
    // button router link
    bottomButtonClick: any,
    // button id
    bottomButtonId: string,
    // handle button click?
    handleBottomButtonClick: boolean,
    // order data
    order: object | any,
    // remaining time for order
    remainingTime: any,
    // selected trade from order
    trade: object | any,
    // selected service from order
    service: object | any,
    // selected taskList from order
    taskList: object | any,
    // flag to show related to button modal or not
    renderRelatedModal: boolean,
    // timer in technician order
    timer: TimerState;
    // after user open task list in active order we will save it with infromation about order id and modifications
    // in tasks - status, problem
    editedTaskLists: any[];

};
export const key: InjectionKey<Store<State>> = Symbol();

const state: State = {
    tabsVisible: tabsVisible,
    tabsMaintenanceRepairVisible: tabsMaintenanceRepairVisible,
    bottomButtonVisible: bottomButtonVisible,
    bottomButtonBackgroundColor: bottomButtonBackgroundColor,
    bottomButtonTextColor: bottomButtonTextColor,
    bottomButtonText: bottomButtonText,
    bottomButtonPrev: bottomButtonPrev,
    bottomButtonNext: bottomButtonNext,
    bottomButtonRouterDirection: bottomButtonRouterDirection,
    bottomButtonClick: bottomButtonClick,
    bottomButtonId: bottomButtonId,
    handleBottomButtonClick: handleBottomButtonClick,
    order: order,
    remainingTime: remainingTime,
    trade: trade,
    renderRelatedModal: renderRelatedModal,
    service: service,
    taskList: taskList,
    editedTaskLists: editedTaskLists,

    timer: {
        timers: [],
    },
};
/*
 * Mutations
 * How we mutate our state.
 * Mutations must be synchronous
 */
export const enum MUTATIONS {
    ORDER =  'ORDER',
    REMAINING_TIME =  'REMAINING_TIME',
    TRADE =  'TRADE',
    SERVICE =  'SERVICE',
    TASK_LIST =  'TASK_LIST',
    ADD_EDITED_TASK_LISTS =  'ADD_EDITED_TASK_LISTS',
    CLEAR_EDITED_TASK_LISTS =  'CLEAR_EDITED_TASK_LISTS',

    START_STOPWATCH = 'START_STOPWATCH',
    STOP_STOPWATCH = 'STOP_STOPWATCH',
    PAUSE_STOPWATCH = 'PAUSE_STOPWATCH',
    INCREMENT_STOPWATCH = 'INCREMENT_STOPWATCH',
    UPDATE_STOPWATCH = 'UPDATE_STOPWATCH',
    PAUSE_START_STOPWATCH = 'PAUSE_START_STOPWATCH',

    ADD_TIMER = 'ADD_TIMER',
    REMOVE_TIMER = 'REMOVE_TIMER',

    CLEAR_BOTTOM_BAR =  'CLEAR_BOTTOM_BAR',

    DEFAULT_TABS =  'DEFAULT_TABS',

    MAINTENANCE_REPAIR_TABS =  'MAINTENANCE_REPAIR_TABS',

    NO_BOTTOM_BAR =  'NO_BOTTOM_BAR',
    BOTTOM_BUTTON_1 =  'BOTTOM_BUTTON_1',
    BOTTOM_BUTTON_2 =  'BOTTOM_BUTTON_2',
    BOTTOM_BUTTON_3 =  'BOTTOM_BUTTON_3',
    BOTTOM_BUTTON_4 =  'BOTTOM_BUTTON_4',
    BOTTOM_BUTTON_5 =  'BOTTOM_BUTTON_5',
    BOTTOM_BUTTON_6 =  'BOTTOM_BUTTON_6',
    BOTTOM_BUTTON_7 =  'BOTTOM_BUTTON_7',
    BOTTOM_BUTTON_8 =  'BOTTOM_BUTTON_8',
    BOTTOM_BUTTON_9 =  'BOTTOM_BUTTON_9',
    BOTTOM_BUTTON_10 =  'BOTTOM_BUTTON_10',
    BOTTOM_BUTTON_11 =  'BOTTOM_BUTTON_11',
    BOTTOM_BUTTON_12 =  'BOTTOM_BUTTON_12',
    BOTTOM_BUTTON_13 =  'BOTTOM_BUTTON_13',
    BOTTOM_BUTTON_14 =  'BOTTOM_BUTTON_14',
    BOTTOM_BUTTON_15 =  'BOTTOM_BUTTON_15',
    BOTTOM_BUTTON_16 =  'BOTTOM_BUTTON_16',
    BOTTOM_BUTTON_17 =  'BOTTOM_BUTTON_17',
    BOTTOM_BUTTON_18 =  'BOTTOM_BUTTON_18',
    BOTTOM_BUTTON_19 =  'BOTTOM_BUTTON_19',
    BOTTOM_BUTTON_20 =  'BOTTOM_BUTTON_20',
    BOTTOM_BUTTON_21 =  'BOTTOM_BUTTON_21',
    BOTTOM_BUTTON_22 =  'BOTTOM_BUTTON_22',
    BOTTOM_BUTTON_23 =  'BOTTOM_BUTTON_23',
    BOTTOM_BUTTON_24 =  'BOTTOM_BUTTON_24',
    BOTTOM_BUTTON_25 =  'BOTTOM_BUTTON_25',
    BOTTOM_BUTTON_26 =  'BOTTOM_BUTTON_26',
    BOTTOM_BUTTON_27 =  'BOTTOM_BUTTON_27',
    BOTTOM_BUTTON_28 =  'BOTTOM_BUTTON_28',
    BOTTOM_BUTTON_29 =  'BOTTOM_BUTTON_29',
    BOTTOM_BUTTON_30 =  'BOTTOM_BUTTON_30',
    BOTTOM_BUTTON_31 =  'BOTTOM_BUTTON_31',
    BOTTOM_BUTTON_32 =  'BOTTOM_BUTTON_32',
    BOTTOM_BUTTON_33 =  'BOTTOM_BUTTON_33',
}
const mutations: MutationTree<State> = {
    // set order. in this case it's not necessary to ge torder from api
    [MUTATIONS.ORDER](state, order) {
        state.order = order;
    },

    // set remaining time in order.
    [MUTATIONS.REMAINING_TIME](state, remainingTime) {
        state.order.remainingTime = remainingTime;
    },

    // set trade. in this case it's not necessary to get trade from api to display services
    [MUTATIONS.TRADE](state, trade) {
        state.trade = trade;
    },


    // set service. in this case it's not necessary to get service from api to display task lists
    [MUTATIONS.SERVICE](state, service) {
        state.service = service;
    },

    // set service. in this case it's not necessary to get service from api to display task lists
    [MUTATIONS.TASK_LIST](state, taskList) {
        state.taskList = taskList;
    },

    // set service. in this case it's not necessary to get service from api to display task lists
    [MUTATIONS.ADD_EDITED_TASK_LISTS](state, editedTaskLists) {
        state.editedTaskLists.push(editedTaskLists);
    },

    // set service. in this case it's not necessary to get service from api to display task lists
    [MUTATIONS.CLEAR_EDITED_TASK_LISTS](state) {
        state.editedTaskLists = editedTaskLists;
    },

    [MUTATIONS.START_STOPWATCH](state: State, orderId: number) {
        let timer: Timer | undefined;
        // check if timer for this order exists. of not create timer
        if(state.timer.timers.find((t) => t.orderId === orderId)){
            timer = state.timer.timers.find((t) => t.orderId === orderId);
        }else{
            mutations[MUTATIONS.ADD_TIMER](state, orderId );
            timer = state.timer.timers.find((t) => t.orderId === orderId);
        }
        if (timer) {
            // if timer is not finished you can resume it. it means that is a new timer or it is paused
            if(!timer.isFinished && !timer.isRunning) {
                // update stopwatch if it is running and not stopped or paused. stopped or paused has seconds summed up,
                // if it is running it means it has only startdate in the latest element in array
                mutations[MUTATIONS.UPDATE_STOPWATCH](state, timer);


                // get current time to add it to startTime
                const currentTimeStamp = new Date().getTime();

                // add new element to array. it doesn't matter if it is paused or ont. We need to add new record to array
                timer.stopwatchStartTimes.push({
                    startTime: currentTimeStamp,
                    endTime: null,
                });
                timer.isRunning = true;
            }else{
                mutations[MUTATIONS.PAUSE_START_STOPWATCH](state, timer);
            }
        }
    },

    [MUTATIONS.STOP_STOPWATCH](state: State, orderId: number) {
        const timer = state.timer.timers.find((t) => t.orderId === orderId);

        if (timer) {
            // get currentTime
            const currentTime = new Date().getTime();

            // stop all running stopwatches in timer. it should be only one, but in case i'm doing it for all without endtime
            timer.stopwatchStartTimes.forEach((time) => {
                if (!time.endTime) {

                    // calc difference in seconds and add it to save seconds
                    timer.seconds += Math.floor((currentTime - time.startTime) / 1000);

                    // convert seconds to proper time
                    const minutes = Math.floor(timer.seconds / 60);
                    const seconds = timer.seconds % 60;
                    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
                    timer.formattedTimer = `${minutes}:${formattedSeconds}`;
                    // add endTime to latest stopwatch
                    time.endTime = currentTime;
                }
            });

            // stop interval
            if (timer.intervalId) {
                clearInterval(timer.intervalId);
                timer.intervalId = null;
            }

            // set flags
            timer.isRunning = false;
            timer.isFinished = true;
        }
    },

    [MUTATIONS.PAUSE_STOPWATCH](state: State, orderId: number) {
        const timer = state.timer.timers.find((t) => t.orderId === orderId);
        if (timer) {
            // get current time to save it in endTime
            const currentTimeStamp = new Date().getTime();

            // get stopwatch timer without endtime - it means is not paused
            const runningStopwatch = timer.stopwatchStartTimes.find((time) => time.endTime === null);

            if (runningStopwatch) {
                runningStopwatch.endTime = currentTimeStamp;
                // calc difference between latest startTime and current time, transform to seconds and add to saved secons value
                timer.seconds += Math.floor((runningStopwatch.endTime - runningStopwatch.startTime) / 1000);

                // convert seconds to proper time and save it
                const minutes = Math.floor(timer.seconds / 60);
                const seconds = timer.seconds % 60;
                const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
                timer.formattedTimer = `${minutes}:${formattedSeconds}`;

                timer.isRunning = false;
                if (timer.intervalId) {
                    clearInterval(timer.intervalId);
                    timer.intervalId = null;
                }
            }
        }
    },

    // update seconds value without incrementation based on saved seconds and latest start time without end time.
    // calc differences in second between current time and latest starttime without end time and sum all
    [MUTATIONS.UPDATE_STOPWATCH](state: State, timer: Timer) {
        console.log('UPDATE_STOPWATCH');
        // find stopwatch that has no end time - it means it is not paused or stopped.
        const activeStopwatch = timer.stopwatchStartTimes.find((time) => !time.endTime);
        // if we have active stopwatch we can sum
        if (activeStopwatch) {
            // Get current time
            const currentTime = new Date().getTime();
            // add difference between current time and latest start time to saved seconds
            timer.seconds += Math.floor((currentTime - activeStopwatch.startTime) / 1000);

            // convert seconds to proper time and save it
            const minutes = Math.floor(timer.seconds / 60);
            const seconds = timer.seconds % 60;
            const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
            timer.formattedTimer = `${minutes}:${formattedSeconds}`;

            // add new element to stopwatchStartTimes with starttime, next time in this case if in update stopwatch
            // i will calc difference between current time and latest checked time.
            timer.stopwatchStartTimes.push({
                startTime: currentTime,
                endTime: null,
            });
        }
    },
    // pause and start stopwatch. use it if you leave screen to save time when you left if. save current timestamp to pause and
    // start new one with the same timestamp.
    [MUTATIONS.PAUSE_START_STOPWATCH](state: State, timer: Timer) {
        mutations[MUTATIONS.PAUSE_STOPWATCH](state, timer.orderId);
        mutations[MUTATIONS.START_STOPWATCH](state, timer.orderId);

    },

    [MUTATIONS.ADD_TIMER](state: State, orderId: number) {
        state.timer.timers.push({
            orderId,
            isRunning: false,
            isFinished: false,
            seconds: 0,
            formattedTimer: 0,
            intervalId: null,
            stopwatchStartTimes: [],
        });
    },

    [MUTATIONS.REMOVE_TIMER](state: State, orderId: number) {
        const index = state.timer.timers.findIndex((t) => t.orderId === orderId);
        if (index !== -1) {
            const timer = state.timer.timers[index];
            if (timer.isRunning) {
                clearInterval(timer.intervalId);
            }
            state.timer.timers.splice(index, 1);
        }
    },

    [MUTATIONS.CLEAR_BOTTOM_BAR](state) {
        state.tabsVisible = true;
        state.tabsMaintenanceRepairVisible = false;
        state.bottomButtonVisible = false;
        state.bottomButtonBackgroundColor =  bottomButtonBackgroundColor;
        state.bottomButtonTextColor =  bottomButtonTextColor;
        state.bottomButtonText =  bottomButtonText;
        state.bottomButtonPrev =  bottomButtonPrev;
        state.bottomButtonNext =  bottomButtonNext;
        state.bottomButtonClick =  bottomButtonClick;
        state.bottomButtonRouterDirection =  bottomButtonRouterDirection;
        state.bottomButtonId =  bottomButtonId;
        state.handleBottomButtonClick = handleBottomButtonClick;
        state.renderRelatedModal = false;

    },

    [MUTATIONS.DEFAULT_TABS](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = true;
    },

    [MUTATIONS.MAINTENANCE_REPAIR_TABS](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.tabsMaintenanceRepairVisible = true;
    },

    [MUTATIONS.NO_BOTTOM_BAR](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
    },
    [MUTATIONS.BOTTOM_BUTTON_1](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonNext = true;
        state.bottomButtonText = 'Next';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-3';
        state.bottomButtonId = 'open-full-size-modal-customer-booking';
    },
    [MUTATIONS.BOTTOM_BUTTON_2](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonNext = true;
        state.bottomButtonText = 'Next';
        state.bottomButtonTextColor = 'color-4';
        state.bottomButtonBackgroundColor = 'background-color-15';
    },
    [MUTATIONS.BOTTOM_BUTTON_3](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonNext = true;
        state.bottomButtonText = 'Select time';
        state.bottomButtonTextColor = 'color-4';
        state.bottomButtonBackgroundColor = 'background-color-15';
    },
    [MUTATIONS.BOTTOM_BUTTON_4](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonNext = true;
        state.bottomButtonText = 'Next';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-3';
    },
    [MUTATIONS.BOTTOM_BUTTON_5](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Book';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-3';
        state.bottomButtonId = 'open-full-size-modal';
        state.bottomButtonClick = '/booked-info-screen';
    },
    [MUTATIONS.BOTTOM_BUTTON_6](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Contact form';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-3';
        state.bottomButtonClick = '/contact-form';
    },
    [MUTATIONS.BOTTOM_BUTTON_7](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Send';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-4';
        state.bottomButtonId = 'full-size-confirmation-model-trigger';
    },
    [MUTATIONS.BOTTOM_BUTTON_8](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'On the road';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-4';
    },
    [MUTATIONS.BOTTOM_BUTTON_9](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Call customer';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-3';
    },
    [MUTATIONS.BOTTOM_BUTTON_10](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Check in';
        state.bottomButtonTextColor = 'color-2';
        state.handleBottomButtonClick = false;
        state.bottomButtonBackgroundColor = 'background-color-7';
        state.bottomButtonClick = '/technician-service-maintenance-extracted';
    },
    [MUTATIONS.BOTTOM_BUTTON_11](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Pause or Finish Maintenance';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-3';
        state.bottomButtonId = 'open-maintenance-modal';
        state.renderRelatedModal = true;
    },
    [MUTATIONS.BOTTOM_BUTTON_12](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Checkout';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-19';
        state.bottomButtonClick = '/technician-service';
    },
    [MUTATIONS.BOTTOM_BUTTON_13](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Next';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-3';
        state.bottomButtonClick = '/home';
    },
    [MUTATIONS.BOTTOM_BUTTON_14](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Next';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-3';
    },
    [MUTATIONS.BOTTOM_BUTTON_15](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Next';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-3';
        state.bottomButtonClick = '/customer-contract';
    },
    [MUTATIONS.BOTTOM_BUTTON_16](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Confirm';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-3';
        state.bottomButtonClick = '/technician-service';
    },
    [MUTATIONS.BOTTOM_BUTTON_17](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Return';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-19';
        state.bottomButtonPrev = true;
        state.bottomButtonRouterDirection = 'back';
    },
    [MUTATIONS.BOTTOM_BUTTON_18](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Check Out';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-7';
        state.bottomButtonClick = consts.RouterNames.SERVICE_FINISHED_CONFIRMATION_MODAL;

    },
    [MUTATIONS.BOTTOM_BUTTON_19](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Accept Logged Time';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonNext = true;
        state.bottomButtonBackgroundColor = 'background-color-3';
    },
    [MUTATIONS.BOTTOM_BUTTON_20](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Accept protocol';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonNext = true;
        state.bottomButtonBackgroundColor = 'background-color-7';
    },
    [MUTATIONS.BOTTOM_BUTTON_21](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Next';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonNext = true;
        state.bottomButtonBackgroundColor = 'background-color-7';
    },
    [MUTATIONS.BOTTOM_BUTTON_22](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Next';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonNext = true;
        state.handleBottomButtonClick = false;
        state.bottomButtonBackgroundColor = 'background-color-4';

    },
    [MUTATIONS.BOTTOM_BUTTON_23](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Next';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonNext = true;
        state.bottomButtonBackgroundColor = 'background-color-7';
    },
    [MUTATIONS.BOTTOM_BUTTON_24](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Scroll down to review and accept Tasks';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonNext = false;
        state.handleBottomButtonClick = false;
        state.bottomButtonBackgroundColor = 'background-color-4';
    },
    [MUTATIONS.BOTTOM_BUTTON_25](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Accept tasks';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonNext = true;
        state.bottomButtonClick = false;
        state.bottomButtonBackgroundColor = 'background-color-3';

    },
    [MUTATIONS.BOTTOM_BUTTON_26](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Add photo';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonNext = false;
        state.bottomButtonClick = true;
        state.bottomButtonBackgroundColor = 'background-color-3';
    },
    [MUTATIONS.BOTTOM_BUTTON_27](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Next';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonNext = true;
        state.bottomButtonBackgroundColor = 'background-color-3';
    },
    [MUTATIONS.BOTTOM_BUTTON_28](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Sign and Create Protocol';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonNext = false;
        state.handleBottomButtonClick = false;
        state.bottomButtonBackgroundColor = 'background-color-4';
    },
    [MUTATIONS.BOTTOM_BUTTON_29](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Sign and Create Protocol';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonNext = false;
        state.bottomButtonBackgroundColor = 'background-color-3';
        state.bottomButtonClick = consts.RouterNames.PROTOCOL_CREATED;
    },
    [MUTATIONS.BOTTOM_BUTTON_30](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Sign and Create Protocol';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-7';
        state.bottomButtonClick = '/service-finished-confirmation-modal';
    },
    [MUTATIONS.BOTTOM_BUTTON_31](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.handleBottomButtonClick = true;
        state.bottomButtonText = 'Start';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-3';
        state.bottomButtonClick = {
            name: consts.RouterNames.ACTIVE_ORDER,
            params: {orderId: state.order.id},
        };
    },
    [MUTATIONS.BOTTOM_BUTTON_32](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Create protocol';
        state.bottomButtonTextColor = 'color-2';
        state.bottomButtonBackgroundColor = 'background-color-3';
        state.bottomButtonClick = {name: consts.RouterNames.CREATE_PROTOCOL};
    },
    [MUTATIONS.BOTTOM_BUTTON_33](state) {
        store.commit(MUTATIONS.CLEAR_BOTTOM_BAR);
        state.tabsVisible = false;
        state.bottomButtonVisible = true;
        state.bottomButtonText = 'Sign ';
        state.bottomButtonBackgroundColor = 'background-color-3';
    },
};

/*
 * Actions
 * Perform async tasks, then mutate state
 */
const actions = {
    startStopwatch({ commit }: { commit: Commit }, orderId: number) {
        commit(MUTATIONS.START_STOPWATCH, orderId);
    },

    stopStopwatch({ commit }: { commit: Commit }, orderId: number) {
        commit(MUTATIONS.STOP_STOPWATCH, orderId);
    },

    pauseStopwatch({ commit }: { commit: Commit }, orderId: number) {
        commit(MUTATIONS.PAUSE_STOPWATCH, orderId);
    },
};
export const store = createStore<State>({
    state,
    mutations,
    actions,
    plugins: [createLogger()],

});

// our own useStore function for types
export function useStore() {
    return baseUseStore(key);
}
