import * as types from "@/types";
import AuthModule from "@/store/modules/auth";
// const tgaApi = axios.create({
//     baseURL: 'https://tga.pthread.pl/api/',
//     headers: {
//     },
// });
export const tgaApi = AuthModule.authenticatedAxios;
export const tgaNAApi = AuthModule.notAuthenticatedAxios;

export async function getModels(modelName: string, filters: types.APIFiltersObject = {}, maxLevel = 3){
    for (const [key, value] of Object.entries(filters)) {
        if (value === undefined) {
            delete filters[key];
            continue;
        }
        filters[key] = JSON.stringify(value);
    }
    const params = new URLSearchParams(filters as any);

    let url: string;
    if (filters.itemsOnPage && filters.pageNo) {
        url = `/models/${modelName}/${maxLevel}/${filters.itemsOnPage}/${filters.pageNo}`;
        params.delete("itemsOnPage");
        params.delete("pageNo");
        url += `?${params}`;
    } else {
        url = `/models/${modelName}/${maxLevel}?${params}`;
    }
    try {
        const response = await tgaApi().get(url);
        return response.data;
    } catch (error: any) {
        if (error.response) {
            return error.message;
        } else if (error.request) {
            return error.message;
        } else {
            return error.message;
        }
    }
}
export async function getModel(modelName: string, filters: types.APIFiltersObject = {}, maxLevel = 3){
    for (const [key, value] of Object.entries(filters)) {
        if (value === undefined) {
            delete filters[key];
            continue;
        }
        filters[key] = JSON.stringify(value);
    }
    const params = new URLSearchParams(filters as any);

    let url: string;
    if (filters.itemsOnPage && filters.pageNo) {
        url = `/model/${modelName}/${maxLevel}/${filters.itemsOnPage}/${filters.pageNo}`;
        params.delete("itemsOnPage");
        params.delete("pageNo");
        url += `?${params}`;
    } else {
        url = `/model/${modelName}/${maxLevel}?${params}`;
    }
    try {
        const response = await tgaApi().get(url);
        return response.data;
    } catch (error: any) {
        if (error.response) {
            return error.message;
        } else if (error.request) {
            return error.message;
        } else {
            return error.message;
        }
    }
}

export function setJWT(jwt: string) {
    tgaApi().defaults.headers.common["Authorization"] = `Token ${jwt}`;
}

export function clearJWT() {
    delete tgaApi().defaults.headers.common["Authorization"];
}

export async function loginUser(user: types.UserSubmit) {
    try {
        const response = await tgaApi().post("/login", { user });
        return { data: response.data, error: null };
    } catch (error) {
        return { data: null, error };
    }
}
export async function sendResetEmail(email: string) {
    try {
        const response = await tgaApi().post("/send_reset_email", { email });
        return { data: response.data, error: null };
    } catch (error) {
        return { data: null, error };
    }
}
export async function resetPassword(uid: number, token: string, newPassword: string) {
    try {
        const response = await tgaApi().post("/reset_password", { uid, token, new_password: newPassword });
        return { data: response.data, error: null };
    } catch (error) {
        return { data: null, error };
    }
}
export async function getServices() {
    try {
        const response = await tgaApi().get("/services");
        return response.data;
    } catch (error: any) {
        if (error.response) {
            return error.message;
        } else if (error.request) {
            return error.message;
        } else {
            return error.message;
        }
    }
}

// Asynchronously fetches data related to trades from the OrderWorkType table
export async function getTrades(order_id: any) {
    try {
        // Retrieve data from the OrderWorkType table based on the optional order_id
        const response = order_id ? await getModels('OrderWorkType', { "order_id": order_id }) : await getModels('OrderWorkType');

        // Create an object to map trades with arrays of work types, task lists, and total time
        const tradesMap: Record<number, { trade: any, workTypes: any[], time: string }> = {};

        // Iterate through the results
        for (const element of response.result) {
            const workType = element.work_type;
            const trade = workType.trade;

            // Fetch the task list for the work type
            let taskLists = [];
            const taskListsResponse = await getTaskLists(workType.id);
            if (taskListsResponse && taskListsResponse.success) {
                taskLists = taskListsResponse.task_lists;
            }

            // Assign taskLists to workType
            workType.taskLists = taskLists;

            // Calculate the total time for workType
            const totalTime = calculateTotalTime(workType);

            // If the trade doesn't exist in the map, create a new object
            if (!tradesMap[trade.id]) {
                tradesMap[trade.id] = {
                    trade: trade,
                    workTypes: [workType],
                    time: totalTime,
                };
            } else {
                // If the trade exists, add a new work type object and update the total time
                tradesMap[trade.id].workTypes.push(workType);
                tradesMap[trade.id].time = addTimes(tradesMap[trade.id].time, totalTime);
            }
        }

        // Sum up the times for all trades
        let totalTradesTime = "00:00:00";
        for (const tradeId in tradesMap) {
            if (Object.prototype.hasOwnProperty.call(tradesMap, tradeId)) {
                totalTradesTime = addTimes(totalTradesTime, tradesMap[tradeId].time);
            }
        }

        // Return the result object containing trades, total time, and success flag
        return { result: { trades: Object.values(tradesMap), totalTime: totalTradesTime }, success: true };
    } catch (error: any) {
        // Handle errors and log relevant information
        if (error.response) {
            return error.message;
        } else if (error.request) {
            return error.message;
        } else {
            return error.message;
        }
    }
}

// Function to calculate the total time for a given workType
function calculateTotalTime(workType: any): string {
    // Initialize totalTime to "00:00:00"
    let totalTime = "00:00:00";

    // Check if taskLists has a filled manual_total_time field
    if (workType.taskLists && workType.taskLists.length > 0) {
        for (const taskList of workType.taskLists) {
            // If manual_total_time is filled, add it to totalTime
            if (taskList.manual_total_time) {
                totalTime = addTimes(totalTime, taskList.manual_total_time);
            } else if (taskList.tasks && taskList.tasks.length > 0) {
                // If manual_total_time is not filled, check tasks in taskList
                for (const task of taskList.tasks) {
                    // If duration_time is present, add it to totalTime
                    if (task.duration_time) {
                        totalTime = addTimes(totalTime, task.duration_time);
                    }
                }
            }
        }
    }

    // Return the calculated total time
    return totalTime;
}

// Function to add two times in the "HH:MM:SS" format
function addTimes(time1: string, time2: string): string {
    // Split the input times into hours, minutes, and seconds components
    const [hours1, minutes1, seconds1] = time1.split(':').map(Number);
    const [hours2, minutes2, seconds2] = time2.split(':').map(Number);

    // Calculate the total hours, minutes, and seconds
    let totalHours = hours1 + hours2;
    let totalMinutes = minutes1 + minutes2;
    let totalSeconds = seconds1 + seconds2;

    // Handle the case when the total number of seconds exceeds 59
    if (totalSeconds >= 60) {
        totalMinutes += Math.floor(totalSeconds / 60);
        totalSeconds %= 60;
    }

    // Handle the case when the total number of minutes exceeds 59
    if (totalMinutes >= 60) {
        totalHours += Math.floor(totalMinutes / 60);
        totalMinutes %= 60;
    }

    // Return the formatted result in "HH:MM:SS" format
    return `${formatTimeComponent(totalHours)}:${formatTimeComponent(totalMinutes)}:${formatTimeComponent(totalSeconds)}`;
}


// Function to format a time component (hours, minutes, seconds)
function formatTimeComponent(component: number): string {
    // Add leading zero if the component is less than 10
    return component < 10 ? `0${component}` : `${component}`;
}

export async function getTaskLists(workTypeId: number) {
    try {
        const response = await tgaApi().get(`/task_lists/${workTypeId}`);
        if (response.data.success) {
            if (response.data.task_lists && response.data.task_lists.length) {
                // modify tasks list - add status and photos nodes
                response.data.task_lists.forEach((taskList: any) => {
                    if (taskList.tasks && taskList.tasks.length) {
                        taskList.tasks.forEach((task: any) => {
                            task.status = "empty";
                            task.is_problem = false;
                            task.problem = {
                                "note": '',
                                "photos": [],
                            };
                        });
                    }
                });
            }
        }
        return response.data;
    } catch (error: any) {
        if (error.response) {
            return error.message;
        } else if (error.request) {
            return error.message;
        } else {
            return error.message;
        }
    }
}
