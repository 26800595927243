import axios, { AxiosInstance } from "axios";
import { User } from "@/types";
import { getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import {store} from "@/store";
import * as Storage from "@/helpers/storage";

@Module({
    namespaced: true,
    store,
    name: "auth",
    dynamic: true,
})
class AuthModule extends VuexModule {
    authenticated = false;
    user = {} as User;

    get currentUser() {
        return this.user;
    }

    get authenticatedAxios() {
        return (isFormData = false): AxiosInstance => {
            axios.defaults.xsrfCookieName = "csrftoken";
            axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

            const headers: { Authorization: string; "Content-type"?: string } = {
                Authorization: `Token ${this.user.token}`,
            };

            if (isFormData === true) {
                headers["Content-type"] = "multipart/form-data;boundary=63c5979328c44e2c869349443a94200e";
            }

            const authAxios = axios.create({
                baseURL: "https://tga.pthread.pl/" + "api/",
                headers: headers,
            });

            return authAxios as AxiosInstance;
        };
    }


    get notAuthenticatedAxios() {
        axios.defaults.xsrfCookieName = "csrftoken";
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

        const headers = {
            "Content-Type": "application/json",
        };
        const authAxios = axios.create({
            baseURL: "https://tga.pthread.pl/" + "api/",
            headers: headers,
        });

        return authAxios;
    }

    @Mutation
    async setAuthenticated(user: User) {
        this.authenticated = true;
        this.user = { ...user } as User;
        await Storage.saveAuthData({
            user_id: user.id,
            email: this.user.email,
            authenticated: true,
            token: this.user.token,
        } as Storage.AuthStorageData);
    }
}

export default getModule(AuthModule);
