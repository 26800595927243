import {createRouter, createWebHistory} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';
import * as consts from '@/consts';
import {UserType} from '@/consts';
import {isUserLoggedIn, isUserPermitted} from "@/helpers";
// in this way, tabs are imported when app is loaded
// other way is to import component inside routes, than it's importen during loading view'
import TabsPage from '../views/TabsPage.vue'
import * as API from "@/store/api";
import * as storage from "@/helpers/storage";
import AuthModule from "@/store/modules/auth";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/intro',
  },
  {
    path: '/intro',
    redirect: '/tabs/intro',
  },
  {
    path: '/home',
    redirect: '/tabs/home',
  },
  {
    path: '/test',
    redirect: '/tabs/test',
  },
  {
    path: '/components',
    redirect: '/tabs/components',
  },
  {
    path: '/customer-booking',
    redirect: '/tabs/customer-booking',
  },
  {
    path: '/order-details/:orderId?',
    name: consts.RouterNames.ORDER_DETAILS,
    redirect: to => {
      return { path: `/tabs/order-details/${to.params.orderId}` };
    }
  },
  {
    path: '/active-order/:orderId?',
    name: consts.RouterNames.ACTIVE_ORDER,
    redirect: to => {
      return { path: `/tabs/active-order/${to.params.orderId}` };
    }
  },
  {
    path: '/select-trade-view/:orderId?',
    name: consts.RouterNames.SELECT_TRADE_VIEW,
    redirect: to => {
      return { path: `/tabs/select-trade-view/${to.params.orderId}` };
    }
  },
  {
    path: '/select-service-view/:orderId?/:tradeId?',
    name: consts.RouterNames.SELECT_SERVICE_VIEW,
    redirect: to => {
      return { path: `/tabs/select-service-view/${to.params.orderId}/${to.params.tradeId}` };
    }
  },
  {
    path: '/select-task-list-view/:serviceId?',
    name: consts.RouterNames.SELECT_TASK_LIST_VIEW,
    redirect: to => {
      return { path: `/tabs/select-task-list-view/${to.params.serviceId}` };
    }
  },
  {
    path: '/task-lists-view/:orderId?/:serviceId?',
    name: consts.RouterNames.TASK_LISTS_VIEW,
    redirect: to => {
      return { path: `/tabs/task-lists-view/${to.params.orderId}/${to.params.serviceId}` };
    }
  },
  {
    path: '/technician-service/:activeSlide',
    alias: '/technician-service',
    name: 'technician-service',
    redirect: to => {
      return { path: '/tabs/technician-service/', query: { activeSlide: to.params.activeSlide } }
    },
  },
  {
    path: '/booked-info-screen',
    redirect: '/tabs/booked-info-screen',
  },
  {
    path: '/order-finished-confirmation-modal',
    redirect: '/tabs/order-finished-confirmation-modal',
  },
  {
    path: '/help-and-support',
    redirect: '/tabs/help-and-support',
  },
  {
    path: '/contact-form',
    redirect: '/tabs/contact-form',
  },
  {
    path: '/take-photo',
    redirect: '/tabs/take-photo',
  },
  {
    path: '/login',
    redirect: '/tabs/login',
  },
  {
    path: '/password-reset-request',
    redirect: '/tabs/password-reset-request',
  },
  {
    path: '/change-password',
    redirect: '/tabs/change-password',
  },
  {
    path: '/technician-service-maintenance-extracted',
    redirect: '/tabs/technician-service-maintenance-extracted',
  },
  {
    path: '/technician-service-maintenance-tasks-maintenance-extracted',
    redirect: '/tabs/technician-service-maintenance-tasks-maintenance-extracted',
  },
  {
    path: '/technician-service-maintenance-tasks-extracted',
    redirect: '/tabs/technician-service-maintenance-tasks-extracted',
  },
  {
    path: '/technician-service-maintenance-tasks-repair-extracted',
    redirect: '/tabs/technician-service-maintenance-tasks-repair-extracted',
  },
  {
    path: '/technician-service-maintenance-notes-extracted',
    redirect: '/tabs/technician-service-maintenance-notes-extracted',
  },
  {
    path: '/technician-create-protocol',
    redirect: '/tabs/technician-create-protocol',
  },
  {
    path: '/technician-create-protocol-slides',
    redirect: '/tabs/technician-create-protocol-slides',
  },
  {
    path: '/orders',
    name: consts.RouterNames.ORDERS,
    redirect: '/tabs/orders',
  },
  {
    path: '/order-finished',
    redirect: '/tabs/order-finished',
  },
  {
    path: '/order-finished/:orderId?',
    name: consts.RouterNames.ORDER_FINISHED,
    redirect: to => {
      return { path: `/tabs/order-finished/${to.params.orderId}` };
    }
  },
  {
    path: '/create-protocol',
    redirect: '/tabs/create-protocol',
  },
  {
    path: '/protocol-created',
    redirect: '/tabs/protocol-created',
  },
  {
    path: '/reported-problems',
    redirect: '/tabs/reported-problems',
  },
  {
    path: '/report-problem',
    name: consts.RouterNames.REPORT_PROBLEM,
    redirect: '/tabs/report-problem',
  },
  {
    path: '/user-profile',
    name: consts.RouterNames.USER_PROFILE,
    redirect: '/tabs/user-profile',
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/home',
      },
      {
        path: 'intro',
        component: () => import('@/views/general/IntroPage.vue'),
      },
      {
        path: 'home',
        component: () => import('@/views/HomePage.vue'),
      },
      {
        path: 'test',
        component: () => import('@/views/TestPage.vue'),
      },
      {
        path: 'components',
        component: () => import('@/views/ComponentsPage.vue'),
      },
      {
        path: 'customer-booking',
        component: () => import('@/views/customer/CustomerBooking.vue'),
      },
      {
        path: 'technician-service/:activeSlide?',
        component: () => import('@/views/technician/TechnicianService.vue'),
        props: true,
      },
      {
        path: 'booked-info-screen',
        component: () => import('@/views/general/BookedInfoScreen.vue'),
      },
      {
        path: 'help-and-support',
        component: () => import('@/views/general/HelpAndSupport.vue'),
      },
      {
        path: 'contact-form',
        component: () => import('@/views/general/ContactForm.vue'),
      },
      {
        path: 'login',
        name: consts.RouterNames.LOGIN,
        component: () => import('@/views/general/Login.vue'),
      },
      {
        path: 'password-reset-request',
        name: consts.RouterNames.PASSWORD_RESET_REQUEST,
        component: () => import('@/views/general/PasswordResetRequest.vue'),
      },
      {
        path: 'change-password',
        name: consts.RouterNames.CHANGE_PASSWORD,
        component: () => import('@/views/general/ChangePassword.vue'),
      },
      {
        path: 'take-photo',
        component: () => import('@/views/general/TakePhoto.vue'),
      },
      {
        path: 'technician-service-maintenance-extracted',
        component: () => import('@/views/technician/TechnicianServiceMaintenanceExtracted.vue'),
      },
      {
        path: 'technician-service-maintenance-notes-extracted',
        component: () => import('@/views/technician/TechnicianServiceMaintenanceNotesExtracted.vue'),
      },
      {
        path: 'technician-service-maintenance-tasks-extracted',
        component: () => import('@/views/technician/TechnicianServiceMaintenanceTasksExtracted.vue'),
      },
      {
        path: 'technician-create-protocol',
        component: () => import('@/views/technician/TechnicianCreateProtocol.vue'),
      },
      {
        path: 'technician-create-protocol-slides',
        component: () => import('@/views/technician/TechnicianCreateProtocolSlides.vue'),
      },
      {
        path: 'order-finished-confirmation-modal',
        name: consts.RouterNames.SERVICE_FINISHED_CONFIRMATION_MODAL,
        component: () => import('@/views/technician/OrderFinishedConfirmationModal.vue'),
      },
      {
        path: 'orders',
        component: () => import('@/views/technician/Orders.vue'),
      },
      {
        path: 'user-profile',
        component: () => import('@/views/general/UserProfile.vue'),
      },
      {
        path: 'order-details/:orderId?',
        component: () => import('@/views/technician/OrderDetails.vue'),
        props: true,
      },
      {
        path: 'technician-service/:orderId?',
        component: () => import('@/views/technician/TechnicianService.vue'),
        props: true,
      },
      {
        path: 'active-order/:orderId?',
        component: () => import('@/views/technician/ActiveOrder.vue'),
        props: true,
      },
      {
        path: 'select-trade-view/:orderId?',
        component: () => import('@/views/technician/SelectTradeView.vue'),
        props: true,
      },
      {
        path: 'select-service-view/:orderId?/:tradeId?',
        component: () => import('@/views/technician/SelectServiceView.vue'),
        props: true,
      },
      {
        path: 'create-protocol',
        name: consts.RouterNames.CREATE_PROTOCOL,
        component: () => import('@/views/technician/CreateProtocol.vue'),
      },
      {
        path: 'protocol-created',
        name: consts.RouterNames.PROTOCOL_CREATED,
        component: () => import('@/views/technician/ProtocolCreated.vue'),
      },

      {
        path: 'select-task-list-view',
        component: () => import('@/views/technician/SelectTaskListView.vue'),
      },
      {
        path: 'select-task-list-view/:serviceId?',
        component: () => import('@/views/technician/SelectTaskListView.vue'),
        props: true,
      },
      {
        path: 'task-lists-view/:orderId?/:serviceId?',
        component: () => import('@/views/technician/TaskListsView.vue'),
        props: true,
      },
      {
        path: 'order-finished/:orderId?',
        component: () => import('@/views/technician/OrderFinished.vue'),
        props: true,
      },
      {
        path: 'reported-problems',
        name: consts.RouterNames.REPORTED_PROBLEMS,
        component: () => import('@/views/technician/ReportedProblems.vue'),
      },
      {
        path: 'report-problem',
        name: consts.RouterNames.REPORT_PROBLEM,
        component: () => import('@/views/technician/ReportProblem.vue'),
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

async function loadUser(user_id:any) {
  return (await API.getModel('User', {id: user_id})).result;
}

router.beforeEach(async (to, from, next) => {
  if (!isUserLoggedIn()) {
    const authData: storage.AuthStorageData = storage.loadAuthData();
    if (authData && authData.token && authData.authenticated) {
      API.setJWT(authData.token);
      const user: any = await loadUser(authData.user_id);
      user.token = authData.token;
      await AuthModule.setAuthenticated(user);
    }
  }
  if (to.name !== consts.RouterNames.LOGIN && to.name !== consts.RouterNames.PASSWORD_RESET_REQUEST && !isUserLoggedIn()) {
    next({ name: consts.RouterNames.LOGIN });
    return;
  }
  if (to.meta.onlyTGA && !isUserPermitted([UserType.TGA_ADMIN, UserType.TGA_MANAGER])) {
    next({ name: consts.RouterNames.LOGIN });
    return;
  }
  next();
});
export default router
