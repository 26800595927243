<template>
  <ion-page id="main-content">
    <the-sidebar></the-sidebar>
    <ion-tabs @ionTabsWillChange="beforeTabChange" @ionTabsDidChange="afterTabChange">
      <!-- https://ionicframework.com/docs/vue/navigation#working-with-tabs -->
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom" v-if="store.state.tabsVisible">
        <ion-tab-button tab="orders" href="/tabs/orders">
          <img :src="require('@/assets/today-icon.svg')" alt="" class="tab-img">
          <ion-label>Today</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="calendar" href="/tabs/calendar">
          <img :src="require('@/assets/calendar-icon.svg')" alt="" class="tab-img">
          <ion-label>Calendar</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
      <ion-tab-bar slot="bottom" v-if="store.state.tabsMaintenanceRepairVisible">
        <ion-tab-button tab="technician-service-maintenance-tasks-maintenance-extracted" href="/tabs/technician-service-maintenance-tasks-maintenance-extracted">
          <img :src="require('@/assets/maintenance-icon.svg')" alt="" class="tab-img">
          <ion-label>Maintenance</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="technician-service-maintenance-tasks-repair-extracted" href="/tabs/technician-service-maintenance-tasks-repair-extracted">
          <img :src="require('@/assets/repair-icon.svg')" alt="" class="tab-img">
          <ion-label>Repair</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
      <!--      <ion-tab-bar class="tabs-button-container " slot="bottom" v-if="store.state.bottomButtonVisible">-->
      <ion-tab-bar class="tabs-button-container " slot="bottom" :class="{ invisible: !store.state.bottomButtonVisible}">
        <bottom-button
            :text="store.state.bottomButtonText"
            :background-color="store.state.bottomButtonBackgroundColor"
            :text-color="store.state.bottomButtonTextColor"
            :arrow-prev="store.state.bottomButtonPrev"
            :arrow-next="store.state.bottomButtonNext"
            :id="store.state.bottomButtonId"
            @click="handleBottomButtonClick"
        ></bottom-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import BottomButton from "@/components/BottomButton";
import { defineComponent, ref } from 'vue';
import {
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs, useIonRouter
} from '@ionic/vue';
import TheSidebar from "@/components/TheSidebar.vue";
import { useStore, MUTATIONS } from '@/store';
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    BottomButton,
    IonLabel,
    IonPage,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs,
    TheSidebar,
  },
  setup() {
    const beforeTabChange = () => {
      // do something before tab change
    }
    const afterTabChange = () => {
      // do something after tab change
    }
    const store = useStore();

    const handleBottomButtonClick = () => {
      if(store.state.bottomButtonClick){
        if(store.state.bottomButtonRouterDirection === 'back'){
          router.back();
        }else{
          // router.push({path:store.state.bottomButtonClick});
          router.push(store.state.bottomButtonClick);
        }
      }
    }
    const router = useRouter();
    return {
      afterTabChange,
      beforeTabChange,
      router,
      store,
      handleBottomButtonClick,
    }
  },
});
</script>
<style lang="scss">
@import "src/theme/common/global.scss";
ion-tab-bar{
  --border: transparent;
  --background: $color2;
  box-shadow: 0 -0.8rem 4.8rem rgba($color1, 0.04);
  -webkit-box-shadow: 0 -0.8rem 4.8rem rgba($color1, 0.04);
  padding: $mainbar-padd-top $mainbar-padd-sides $mainbar-padd-bottom $mainbar-padd-sides;
  gap: 2rem;
  &.tabs-button-container{
    min-height: $mainbar-height;
    padding: 0;
  }
  ion-tab-button{
    --color: #{$color4};
    --background: transparent;
    --color-selected: #{$color3};
    font-weight: $font-normal;
    line-height: (15 / 12);
    letter-spacing: 0.03em;
    @include transition();
    &#tab-button-technician-service-maintenance-tasks-repair-extracted{
      --color-selected: #{$color7};
    }
    .tab-img{
      filter: grayscale(1) brightness(1.1);
      -webkit-filter: grayscale(1) brightness(1.1);
      display: block;
      width: 2.4rem;
      height: auto;
      margin-bottom: 1rem;
      @include transition();
      opacity: 0.4;
    }
    &.tab-selected{
      .tab-img{
        filter: none;
        -webkit-filter: none;
        opacity: 1;
      }
    }
  }

  //&.white-shadow{
  //  box-shadow: 0 -28px 65px 33px rgba($color2, 0.7);
  //  -webkit-box-shadow: 0 -28px 65px 33px rgba($color2, 0.7);
  //}
}
ion-label{
  font-size: #{$rem-1-2};
}
.invisible{
  display: none;
}
.unclickable{
  pointer-events: none;
}
</style>
